const usePromoGiftData = () => {
	const { t } = useT();

	const { handleOpen: handleMagicBoxesOpen } = useMagicBoxesController();
	const { data: bonuses, pending: bonusesPending } = useBonusesData();
	const { totalTasksPrize } = useGetPageQuestData({ immediate: false });
	const { data: quest, pending: questPending, isSeasonQuest } = useQuestData();
	const { data: promotions, pending: promoPending } = useAsyncFetch({
		path: "/rest/page/promotions/",
		method: "get",
		options: { cached: true }
	});

	const promoOffer = computed(
		() =>
			promotions.value?.payload?.promotions?.find((item) => item.data?.promoOfferPreset?.forCompleteProfile === false)
				?.data
	);

	const availableGiftType = computed(() => {
		if (promoOffer.value) {
			return "offer";
		}
		if (bonuses.value?.isMagicBoxAvailable) {
			return "magicBox";
		}
		if (quest.value?.questInfo && !isSeasonQuest.value) {
			return "quest";
		}

		return "";
	});

	const badgeLabel = computed(() =>
		promoOffer.value?.promoOfferPreset?.badgeLabel ? parseJSON(promoOffer.value.promoOfferPreset.badgeLabel) : {}
	);

	const giftData = computed(() => ({
		offer: {
			title: badgeLabel.value?.percent ?? "",
			subTitle: "Bonus",
			icon: "/nuxt-img/gift/giftbox.png",
			card: {
				title: "",
				subTitle: "",
				badgeData: `${badgeLabel.value?.percent ?? ""} ${badgeLabel.value?.text ?? ""}`,
				bestDeal: promoOffer.value?.promoOfferPreset?.bestDeal,
				coins: promoOffer.value?.promoOfferPreset?.coins,
				entries: promoOffer.value?.promoOfferPreset?.entries,
				piggyCoins: promoOffer.value?.promoOfferPreset?.moneyBoxAmount ?? 0,
				buttonLabel: "buy now",
				price: `$${promoOffer.value?.money}`,
				usualPrice: promoOffer.value?.promoOfferPreset?.usualPrice
			}
		},
		magicBox: {
			title: "Open",
			subTitle: "Now",
			icon: "/nuxt-img/gift/magicbox.png",
			card: {
				title: t("Magic box"),
				subTitle: "get up to",
				badgeData: "",
				bestDeal: false,
				coins: Number(t("magicbox_prize_coins")),
				entries: Number(t("magicbox_prize_entires")),
				buttonLabel: "try now for free",
				price: "",
				usualPrice: 0
			}
		},
		quest: {
			title: quest.value?.isSubscribed ? "open" : "join",
			subTitle: "Quest",
			icon: "/nuxt-img/gift/giftquest.png",
			card: {
				title: quest.value?.questInfo?.title || "Tao quest",
				subTitle: "prize pool",
				badgeData: "",
				bestDeal: false,
				coins: "",
				entries: totalTasksPrize?.value?.totalEntries,
				buttonLabel: quest.value?.isSubscribed ? "Open the quest" : "Join the quest",
				price: "",
				usualPrice: 0
			}
		}
	}));

	const depositByMoney = () => {
		window?.$cash?.$router?.push(`/cash/deposit-by-money`);
		dispatchGAEvent({ event: "click_button", button_name: "make_deposit", location: "gift" });
	};

	const depositByMoneyWithOffer = () => {
		window?.$cash?.$router?.push(`/cash/deposit-by-money/${promoOffer.value?.promoOfferPreset?.id}/promoOffer/`);
		dispatchGAEvent({ event: "click_button", button_name: "make_deposit", location: "gift" });
	};

	const openMagicBox = () => {
		handleMagicBoxesOpen();
		dispatchGAEvent({ event: "click_button", button_name: "spin_win", location: "gift" });
	};

	const openQuest = () => {
		navigateTo("/quest/");
		dispatchGAEvent({ event: "click_button", button_name: "quest", location: "gift" });
	};

	const onGiftClick = () => {
		const actionsByGiftType: Record<string, () => void> = {
			offer: depositByMoneyWithOffer,
			magicBox: openMagicBox,
			quest: openQuest
		};

		actionsByGiftType[availableGiftType.value]();
	};

	return {
		bonusesPending,
		questPending,
		promoPending,
		availableGiftType,
		giftData,
		onGiftClick,
		depositByMoney,
		depositByMoneyWithOffer,
		openMagicBox,
		openQuest
	};
};

export default usePromoGiftData;
