<script setup lang="ts">
const giftRef = ref();
const isShowGiftCard = ref(false);

onClickOutside(giftRef, () => (isShowGiftCard.value = false));

const { t } = useT();
const { availableGiftType, giftData, onGiftClick } = usePromoGiftData();

const activeGiftData = computed(() => availableGiftType.value && giftData.value[availableGiftType.value]);

const handleToggleContent = () => {
	isShowGiftCard.value = !isShowGiftCard.value;

	if (isShowGiftCard.value) {
		dispatchGAEvent({
			event: "click_button",
			button_name: "gift",
			form_name: "offer",
			location: "fixed"
		});
	}
};

const handleClickOnGift = () => {
	onGiftClick();
	handleToggleContent();
};
</script>
<template>
	<div v-if="activeGiftData" ref="giftRef" class="promo-gift text-coro" :class="[availableGiftType]">
		<div class="gift-badge" :class="{ 'is-closed': isShowGiftCard }" @click="handleToggleContent">
			<NuxtImg :src="activeGiftData.icon" class="icon" format="avif" loading="lazy" :width="40" />

			<div class="gift-text">
				<AText :modifiers="['bold', 'uppercase']">{{ t(activeGiftData.title as string) }}</AText>
				<AText variant="topeka">
					{{ t(activeGiftData.subTitle) }}
				</AText>
			</div>
		</div>
		<div class="gift-content" :class="{ 'is-open': isShowGiftCard }">
			<AIconButton variant="ghost" @click="handleToggleContent">
				<NuxtIcon name="12/close" filled />
			</AIconButton>

			<div v-if="activeGiftData.card?.badgeData" class="badge-wrapper">
				<ABadge
					variant="info"
					autosize
					:background="activeGiftData.card?.bestDeal ? 'var(--cairns)' : 'var(--changzhou)'"
				>
					<AText variant="tempe" class="text-coro" :modifiers="['uppercase', 'semibold']" as="em">
						{{ activeGiftData.card?.bestDeal ? t("Best deal") : t("Most popular") }}
					</AText>
				</ABadge>

				<ABadge variant="info" autosize>
					<AText variant="tempe" class="text-coro" :modifiers="['uppercase', 'semibold']" as="em">
						{{ activeGiftData.card.badgeData }}
					</AText>
				</ABadge>
			</div>

			<AText v-else type="small" :modifiers="['bold', 'uppercase']">
				{{ t(activeGiftData.card.title) }} - {{ t(activeGiftData.card.subTitle) }}
			</AText>

			<AText v-if="activeGiftData.card.coins" class="d-flex align-items-center gap-1" type="h4">
				<NuxtIcon class="coins" name="24/coins" filled />
				{{ numberFormat(activeGiftData.card.coins) }}
			</AText>

			<div class="d-flex flex-column align-items-center">
				<AText v-if="activeGiftData.card.entries" class="secret-coins" variant="toledo" :modifiers="['bold']">
					<NuxtIcon name="24/secret-coins" filled />
					{{ numberFormat(activeGiftData.card.entries) }}
					<AText v-if="availableGiftType !== 'quest'" variant="ternopil" :modifiers="['normal', 'uppercase']">
						{{ t("free secret coins") }}
					</AText>
				</AText>

				<AText v-if="activeGiftData?.card?.piggyCoins" :size="10" :modifiers="['uppercase']">
					<AText :size="10" :modifiers="['bold']">+ {{ activeGiftData?.card?.piggyCoins }}</AText>
					{{ t("for Piggy bank") }}
				</AText>
			</div>

			<AButton variant="primary" size="s" :modifiers="['wide']" @click="handleClickOnGift">
				{{ t(activeGiftData.card.buttonLabel) }} {{ activeGiftData.card.price ?? "" }}
			</AButton>
			<AText
				v-if="activeGiftData.card.usualPrice"
				class="usual-price"
				variant="topeka"
				:modifiers="['bold', 'uppercase']"
			>
				{{ t("was") }} <s>${{ numberFormat(activeGiftData.card.usualPrice, { minimumFractionDigits: 2 }) }}</s>
			</AText>
		</div>
	</div>
</template>
<style lang="scss" scoped>
.promo-gift {
	position: fixed;
	right: 0;
	bottom: 200px;
	z-index: 14;

	@include media-breakpoint-up(lg) {
		display: none;
	}

	&.offer {
		.gift-badge,
		.gift-content {
			background: var(--gradient-2);
		}

		.gift-content {
			padding: 10px 24px;
		}
	}

	&.magicBox {
		.gift-badge,
		.gift-content {
			background: var(--guangzhou);
		}
	}

	&.quest {
		.gift-badge,
		.gift-content {
			background: var(--gradient-1);
		}

		.gift-content .secret-coins .nuxt-icon {
			font-size: 24px;
		}

		.gift-content {
			padding: 16px 24px;
			.secret-coins {
				font-size: 28px;
				line-height: 36px;
				margin-top: -12px;
			}
		}
	}
}

.gift-badge {
	width: 90px;
	height: 60px;
	padding: 10px 7px 10px 2px;
	box-shadow: 0 4.5px 4.5px var(--chile);
	border-radius: 10px 0 0 10px;
	cursor: pointer;
	transition: 0.2s ease;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;

	&.is-closed {
		transform: translateX(100%);
		opacity: 0;
	}

	.icon {
		position: absolute;
		left: 2px;
		top: 0;
		bottom: 0;
		margin: auto 0;
		display: flex;
		align-items: center;
	}
}

.gift-text {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: calc(100% - 40px);
	margin-left: auto;
}

.gift-content {
	position: absolute;
	right: 0;
	top: 0;
	width: 322px;
	max-width: calc(100vw - 30px);
	min-height: 194px;
	padding: 24px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: gutter(1.5);
	border-radius: 10px;
	transition: 0.3s ease;
	transform: rotate(-90deg);
	transform-origin: top right;
	opacity: 0;

	&.is-open {
		transform: rotate(0);
		opacity: 1;
	}

	.badge-wrapper {
		display: flex;
		gap: 4px;
	}

	.badge {
		padding: 0 gutter(0.5);
		box-shadow: none;
	}

	.coins {
		font-size: 24px;
	}

	.secret-coins {
		font-size: 16px;
		margin-top: -8px;
		display: flex;
		align-items: center;
		gap: 4px;

		.ternopil {
			vertical-align: middle;
		}
		.nuxt-icon {
			font-size: 20px;
		}
	}
}

.icon-button {
	position: absolute;
	top: 10px;
	right: 10px;
	font-size: 14px;
	cursor: pointer;
}
</style>
